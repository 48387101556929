<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>名单管理</el-breadcrumb-item>
            <el-breadcrumb-item>名单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入家长名称" v-model="QueryInfo.name" clearable @clear="GetSchoolList"> 
                        <el-button slot="append" icon="el-icon-search" @click="GetSchoolList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">新建名单</el-button>
                </el-col>
            </el-row>
            <el-table :data="ListofLists" border stripe>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="联系人" prop="name"></el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
    data(){
        return{
            QueryInfo:{

            },
            ListofLists:[]
        }
    }
}
</script>

<style lang="less" scoped>

</style>